import ImgFolder from "../../../assets/img/folder.png";
import React from "react";
import {Delete, PencilEdit} from "../../Icons";

interface IButtonFolder {
    description: string;
    onClick: () => void;
    onClickEdit?: () => void;
}

export default function ButtonFolder({description, onClick, onClickEdit}: IButtonFolder) {
    return(
        <div className={'flex'}>
            <div className={'flex flex-col items-center hover:cursor-pointer'} onClick={() => onClick()}>
                <img className={'w-[100px] h-[100px]'} src={ImgFolder}/>
                <div className={'text-[14px]'}>{description}</div>
            </div>
            {
                onClickEdit ?
                    <div className={'flex flex-col gap-2'}>
                        <button  onClick={onClickEdit} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <PencilEdit color={'#E0A422'} />
                        </button>
                    </div>
                    : ''
            }

        </div>
    )
}