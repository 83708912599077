import {useParams} from "react-router";
import React, { useState, useEffect } from "react";
import foldersApi from "../../../services/foldersApi";
import {useNavigate} from "react-router-dom";
import folderApi from "../../../services/foldersApi";
import ButtonFolder from "../../../components/button/ButtonFolder";
import PageContainer from "../../../components/container/PageContainerClient";

export default function FolderFilesClient() {
    const [folders, setFolders] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const getFolders = async () => {
        const {data, isError} = await folderApi.listAll();

        if (!isError) {
            if (data && Array.isArray(data)) {
                setFolders(data)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getFolders()
    }, []);

    return (
        <PageContainer>
            <div className={'border border-system-gray-gray80 rounded-xl  bg-system-blue-medium h-[calc(95vh_-_90px)] overflow-auto scrollbar'}>
                <div className={'p-5 md:flex xs:flex-row border-b items-center justify-between border-system-gray-gray80 text-xl font-bold'}>
                    Pastas de arquivos
                </div>
                <div className={'flex flex-wrap gap-3 w-full p-5'}>
                    {
                        folders.map((folder) => (
                            <ButtonFolder
                                key={folder.id}
                                description={folder.name}
                                onClick={() => navigate(`/cliente/pastas/${folder.id}/arquivos`)}
                            />
                        ))
                    }
                </div>
            </div>
        </PageContainer>
    )


}