import ListPageLayout from "../../components/layout/ListPageLayout";
import clientApi from "../../services/clientApi";
import propertyApi from "../../services/propertyApi";

export default function Properties() {
    return(
        <ListPageLayout
            title={'Cadastro de Imóveis'}
            urlCreate={'/propriedades/formulario'}
            queries={{['relations']: 'type' }}
            collunSearch={'description'}
            permission={'property.edit'}
            table={
                {
                    loader: propertyApi.all,
                    columns: [
                        { label: "Descrição", index: "description", type: 'string'},
                        { label: "Data Cadastro", index: "created_at", type: 'dateT'},
                        { label: "Setor", index: "neighborhood", type: 'string'},
                        { label: "Endereço", index: "address", type: 'string'},
                        { label: "Tipo de imóvel", index: "type.description", type: 'string'},
                    ]
                }
            }
        />
    )
}