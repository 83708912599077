import noImage from "../../assets/img/noImage.png";
import Dropdown from "../dropdown/Dropdown";
import {HiDotsHorizontal} from "react-icons/hi";
import React, {useState} from "react";
import Str from "../../helpers/Str";
import {IoClose} from "react-icons/io5";
import {Download} from "../Icons";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import useModal from "../../hooks/useModal";
import PostComponent from "../PostComponent";
import Modal from "../modal/Modal";


interface ICardPosts {
    post?: any;
    images?: any;
    description?: string;
    documents?: any;
    dataCreation?: string;
    userCreate: any;
    id: number;
    onDeletePost?: any;
    onEditPost?: any;
    onClickImage?: any;
    onLikePost?: any;
    onLikePostCount?: any;
    isLiked?: boolean;
    permissionEdit?: boolean;
}

export default function CardPosts({
    post,
    images,
    description,
    documents,
    dataCreation,
    userCreate,
    id,
    onDeletePost,
    onEditPost,
    onLikePost,
    onLikePostCount,
    isLiked = false,
    onClickImage,
    permissionEdit
}: ICardPosts) {

    const dropDownOptions = [
        {
            label: 'Editar',
            onClick: () => onEditPost(post)
        },
        {
            label: 'Excluir',
            onClick: () => onDeletePost(id)
        }
    ]

    const hasLikes = post?.likes && post.likes.length > 0;

    const text = description;
    const clickableText = text ? text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" style="color: #E0A422" target="_blank" rel="noopener noreferrer">$1</a>') : '';

    return(
        <div className={'rounded-xl  w-full bg-[#1C1C24] p-5'}>
            <div className={'flex justify-between'}>
                <div className={'flex gap-3 items-center'}>
                    <div>
                        <img className={'w-[50px] h-[50px] rounded-full '} src={userCreate.photo_path ? userCreate.photo_path : noImage}/>
                    </div>
                    <div >
                        <div className={'text-[#FAFAFB] text-[20px]'}>
                            {userCreate.name}
                        </div>
                        <div className={'text-[#696974] text-[14px]'}>
                            {Str.convertDateStringComplet(dataCreation)}
                        </div>
                    </div>
                    {onLikePost && (
                        <div className="flex items-center mt-4">
                            <button
                                className="flex items-center"
                                onClick={() => onLikePost(id)}
                            >
                                {isLiked ? (
                                    <AiFillHeart className="text-red-500" size={24} />
                                ) : (
                                    <AiOutlineHeart className="text-gray-500" size={24} />
                                )}
                            </button>
                        </div>
                    )}
                    {onLikePostCount && (
                        <div className="flex items-center mt-4">
                            <button
                                className="flex items-center"
                                onClick={() => onLikePostCount(id)}
                            >
                                {hasLikes ? (
                                    <AiFillHeart className="text-red-500" size={24} />
                                ) : (
                                    <AiOutlineHeart className="text-gray-500" size={24} />
                                )}
                            </button>
                        </div>
                    )}
                </div>
                {
                    permissionEdit ?
                        onEditPost ?
                            <div>
                                <Dropdown
                                    trigger={<HiDotsHorizontal
                                        size={20}
                                        color={'#92929D'}
                                    />}
                                    items={dropDownOptions}
                                />
                            </div>
                            :
                            ''
                        :
                        ''
                }

            </div>
                <div className={'mt-5 text-[16px] text-[#B5B5BE]'}>
                    <p dangerouslySetInnerHTML={{ __html: clickableText }} />
                </div>
                <div className={'mt-5'}>
                    <div className="grid grid-cols-2 gap-6">

                        {
                            images.length ?
                                images.length === 1 ?
                                    <div className={'col-span-2'}>
                                        <img onClick={() => onClickImage(post)} className="max-h-[600px] w-full rounded-lg hover:cursor-pointer" src={images[0].url_path}/>
                                    </div>
                                    :
                                    <>
                                        {
                                            images.map((image: any) => {
                                                return(
                                                    <div className={'md:col-span-1 sm:col-span-2 xs:col-span-2'}>
                                                        <img
                                                            onClick={() => onClickImage(post)}
                                                            className="h-[350px] w-[350px] rounded-lg hover:cursor-pointer"
                                                            src={image.url_path}
                                                        />
                                                    </div>
                                                );
                                            })
                                        }


                                    </>
                                :
                                ''
                        }
                    </div>
                    {
                        documents.length ?
                            <div className={'flex flex-col w-full mt-5 gap-5'}>
                                {
                                    documents.map((document: any, index: number) => {

                                        const url = document.url_path ? document.url_path : document.file_path;

                                        return  (
                                            <div className={'flex border border-[#13131A] hover:border-border items-center justify-between w-full p-3 h-[73px] rounded-xl bg-[#13131A]'}>
                                                <div className={'text-[#B5B5BE]'}>
                                                   {document.original_name}
                                                </div>
                                                <div className={'flex w-[50%] text-[#64646C] justify-between'}>
                                                    <div >
                                                        {Str.convertDateCreatedAt(document.created_at)}
                                                    </div>
                                                    <div>
                                                        <button onClick={()=> window.open(url, "_blank")} className={'hover:text-border'}>
                                                            <Download/>
                                                        </button>
                                                    </div>
                                                </div>


                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            ''
                    }
                </div>
            </div>

    )
}