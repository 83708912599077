import PageContainer from "../../../components/container/PageContainer";
import AddButton from "../../button/AddButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import Table from "../../Table";
import useTable from "../../../hooks/useTable";
import Alert from "../../../helpers/Alert";
import React, {useEffect, useState} from "react";
import Input from "../../inputs/Input";
import { CgSearch } from "react-icons/cg";
import SvgArrowLeft02StrokeRounded from "../../Icons/ArrowLeft02StrokeRounded";

import Storage from "../../../helpers/Storage";
import PageContainerClient from "../../container/PageContainerClient";
interface IListPageLayout {
    title: string,
    urlReturn?: string;
    urlCreate: string,
    queries?: {
        [key: string]: string
    }
    table: {
        columns: any[];
        loader: any;
    },
    collunSearch?: string;
    permission?: string;
    client?: boolean;
}

export default function ListPageLayout<T = any>(
{
    title,
    urlReturn,
    urlCreate,
    table,
    queries,
    collunSearch,
    permission,
    client
}: IListPageLayout) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [dataSearch, setDataSerach] = useState('');
    const permissions: any = Storage.getUserPermissions();

    const { tableProps, loadData } = useTable<T>({
        denyInitialLoad: true,
        dataLoader: async (params) => {
            if (queries){
                if (queries.where){
                    params = { ...params, where: queries.where };
                }
            }
            if (dataSearch) {
                // @ts-ignore
                params = { ...params, like: [collunSearch, dataSearch] };
            }
            const { data, isError } = await table.loader({...params} as any);
            if (isError) {
                Alert.error(data.message || data.error || 'Falha ao carregar dados.');
                return;
            }
            return data as any;
        }
    });
    useEffect(() => {
        loadData()
    }, [])

    const onClickTable = (data?: any) => {
        navigate(urlCreate+'/'+data.id)
    }

    return(
        client ?
            <PageContainerClient>
                <div className={'border border-system-gray-gray80 rounded-xl mb-[90px] bg-system-blue-medium'}>
                    <div className={'p-5 md:flex xs:flex-row border-b items-center justify-between border-system-gray-gray80'}>
                        <div className="flex items-center gap-0">
                            {urlReturn && (
                                <button type="button" onClick={() => navigate(urlReturn)}>
                                    <SvgArrowLeft02StrokeRounded width={20} />
                                </button>
                            )}
                            <div className="text-xl font-bold text-left ml-2"> { }
                                {title}
                            </div>
                        </div>
                        <div className="flex  items-center gap-2">
                            <Input
                                placeholder="Procurar"
                                className={'md:w-[25rem]'}
                                value={dataSearch}
                                setValue={(v) => setDataSerach(v)}
                            />
                            <button onClick={loadData} className="p-2">
                                <CgSearch size={30} color="#E0A422" />
                            </button>
                        </div>

                    </div>

                    <div className="table_component" role="region">
                        <Table
                            {...tableProps}
                            onClick={onClickTable}
                            data={tableProps.data}
                            columns={table.columns}
                        />
                    </div>


                    {permissions?.includes(permission) ? <AddButton onClick={() => navigate(urlCreate)}/> : ''}

                </div>
            </PageContainerClient>
            :
            <PageContainer>
                <div className={'border border-system-gray-gray80 rounded-xl mb-[90px] bg-system-blue-medium'}>
                    <div className={'p-5 md:flex xs:flex-row border-b items-center justify-between border-system-gray-gray80'}>
                        <div className="flex items-center gap-0">
                            {urlReturn && (
                                <button type="button" onClick={() => navigate(urlReturn)}>
                                    <SvgArrowLeft02StrokeRounded width={20} />
                                </button>
                            )}
                            <div className="text-xl font-bold text-left ml-2"> { }
                                {title}
                            </div>
                        </div>
                        <div className="flex  items-center gap-2">
                            <Input
                                placeholder="Procurar"
                                className={'md:w-[25rem]'}
                                value={dataSearch}
                                setValue={(v) => setDataSerach(v)}
                            />
                            <button onClick={loadData} className="p-2">
                                <CgSearch size={30} color="#E0A422" />
                            </button>
                        </div>

                    </div>

                    <div className="table_component" role="region">
                        <Table
                            {...tableProps}
                            onClick={onClickTable}
                            data={tableProps.data}
                            columns={table.columns}
                        />
                    </div>


                    {permissions?.includes(permission) ? <AddButton onClick={() => navigate(urlCreate)}/> : ''}

                </div>
            </PageContainer>

    )
}