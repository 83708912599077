import {useParams} from "react-router";
import {useEffect, useState} from "react";
import foldersApi from "../../../../services/foldersApi";
import ListPageLayout from "../../../../components/layout/ListPageLayout";
import filesApi from "../../../../services/filesApi";

export default function FilesClient() {
    const { folderId } = useParams();
    const [folderName, setFolderName] = useState("...");

    useEffect(() => {
        async function fetchFolderName() {
            try {
                const response = await foldersApi.one(String(folderId));
                setFolderName(response.data.name);
            } catch (error) {
                console.error("Erro ao buscar o nome da pasta:", error);
                setFolderName("Pasta não encontrada");
            }
        }

        if (folderId) {
            fetchFolderName();
        }
    }, [folderId]);

    return (
        <ListPageLayout
            client
            title={`Arquivos - ${folderName}`}
            urlReturn={'/cliente/pastas'}
            urlCreate={`/cliente/pastas/${folderId}/arquivos/formulario`}
            queries={{
                ['relations']: 'type,userCreate,properties',
                ['where']: `id_folder,=,${folderId}`
            }}
            collunSearch={'description'}
            permission={'files.edit'}
            table={
                {
                    loader: filesApi.all,
                    columns: [
                        { label: "Descrição", index: "description", type: 'string'},
                        { label: "Tipo do Documento", index: "type.description", type: 'string'},
                        { label: "Imóveis", index: "properties", type: 'array'},
                        { label: "Usuário", index: "user_create.name", type: 'string'},
                        { label: "Data", index: "created_at", type: 'dateT'},
                    ]
                }
            }
        />
    )
}